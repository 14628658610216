import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import Navbar from './Components/Routes/navbar';
import Hero from './Components/Routes/hero';
import About from './Components/Routes/about';
import Vision from './Components/Routes/vision';
import Product from './Components/Routes/product';
import Associations from './Components/Routes/associations';
import Contact from './Components/Routes/contact';
import Footer from './Components/Routes/footer';

function App() {
  return (
    <Router>
      <div className="App">
        <div className='parallax'></div>
        <Navbar />
        <Routes>
          <Route exact path="/" element={[<Hero />, <About />,<Vision/>, <Product/>,<Associations />, <Contact />, <Footer />]} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
