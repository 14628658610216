import React from 'react';
import '../../App.css';
import '../Styles/about.css';

function about() {
  return (
    <div className="about" id="about">
      <div className="main">
        <div className="bg-image"></div>
      </div>
      <div className="description"><hr/><br/>
        Our company has continued to strive towards becoming a complete multi-disciplinary practice offering our Clients the specialist individual attention and solutions required by an ever-changing project environment.<br/><br/><hr/>
      </div>
    </div>
  );
}

export default about;