import React from 'react';
import '../../App.css';
import '../Styles/associations.css';
import img1 from '../Assets/association1.png';
import img2 from '../Assets/association2.png';
import img3 from '../Assets/association3.png';
import img4 from '../Assets/association4.png';
import img5 from '../Assets/association5.png';
import img6 from '../Assets/association6.png';
import img7 from '../Assets/association7.png';
import img8 from '../Assets/association8.png';
import img9 from '../Assets/association9.png';
import img10 from '../Assets/association10.png';

function associations() {
    return (
        <div className="associations" id="associations">
            <div className="assHead">ASSOCIATIONS</div>
            <div className="assImg">
                <div className="assRow1">
                    <div className="img1 imgHover">
                        <a href="https://www.sail.co.in/en/home"><img src={img1} alt="1"/></a>
                    </div>
                    <div className="img2 imgHover">
                        <a href="https://www.tatastructura.com/"><img src={img2} alt="2"/></a>
                    </div>
                    <div className="img3 imgHover">
                        <a href="https://ravindrapvcpipes.com/"><img src={img3} alt="3"/></a>
                    </div>
                    <div className="img4 imgHover" >
                        <a href="https://www.jindal.com/jindal-star.html"><img src={img4} alt="4"/></a>
                    </div>
                    <div className="img5 imgHover">
                        <a href="https://www.jindal.com/msl/index.html"><img src={img5} alt="5"/></a>
                    </div>
                    <div className="img6 imgHover">
                        <a href="https://www.tatapipes.com/"><img src={img6} alt="1"/></a>
                    </div>
                    <div className="img7 imgHover">
                        <a href="https://www.unikpipe.com/"><img src={img7} alt="2" /></a>
                    </div>
                    <div className="img8 imgHover">
                        <a href="https://www.apollopipes.com"><img src={img8} alt="3"/></a>
                    </div>
                    <div className="img9 imgHover">
                        <a href="https://www.zolotovalves.com/"><img src={img9} alt="4" width="210px" height="172px"/></a>
                    </div>
                    <div className="img10 imgHover">
                        <a href="https://tatabluescopesteel.com/"><img src={img10} alt="5"/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default associations;