import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// const accountSid = 'AC849e1cf64e28dd757464ffe91a95aeb8';
// const authToken = '1d41dfa981dbd33a00f5968742098ee9';
// const client = require('twilio')(accountSid, authToken);

// import 'twilio';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
