
import React, { useEffect } from 'react';
import '../../App.css';
import '../Styles/vision.css';
import image from '../Assets/vision-image2.png'

function Vision() {
  useEffect(() => {
    function visible(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= -5 &&
        rect.left >= -5 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    function handleAnimations() {
      var contentElement = document.querySelector('.vision .content');
      var imageElement = document.querySelector('.vision .vision_image');

          if (visible(contentElement)) {
            contentElement.style.opacity = 1;
            contentElement.style.transition = 'opacity 0.5s ease';
          }
        
          if (visible(imageElement)) {
            function handleResize() {
              if (window.innerWidth > 980) {
                imageElement.style.transform = 'scale(1)';
                imageElement.style.transition = 'transform 0.3s ease';
            }
            else {
              imageElement.style.transform = 'scale(0.9)';
            imageElement.style.transition = 'transform 1s ease';
          }
          } 
        window.addEventListener('resize', handleResize);
        handleResize();
        }
      }
     
   
    window.addEventListener('scroll', handleAnimations);

  }, []);

  return (
    <div className="vision" id='vision'>
      <div className="vision_heading">Our Vision</div>
     <div className="vision_body">
        
          <div className="img" ><img alt= "vision logo" src={image} className="vision_image" /></div>
          <div className="content">
          Our vision is to grow our multi-disciplinary team in order to offer a broad spectrum of specialist Pipeline Consulting services to become our Clients' preferred Professional Service Provider (PSP) choice through excellence and efficiency in all aspects of the project life cycle. We work hard to cater to our client`s needs with the best metal solutions around the world, & revolutionizing the supply chains of the industries we serve. We leave a long-lasting impression in our client`s minds by supplying optimum quality products that will exceed expectations and set new standards!
        </div> 
      </div> 
    </div>
  );
}

export default Vision;